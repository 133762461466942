import { Controller } from "@hotwired/stimulus"

const EMPTY_FIELD_MESSAGE = "Please fill this field"
const EMAIL_INCORRECT_FORMAT = "Provided email address is incorrect"
const ERROR_SELECTOR = ".utils\\:error"

export default class EmailFieldController extends Controller<HTMLInputElement> {
  static values = { serverError: String }

  declare serverErrorValue: string

  connect(): void {
    this.element.setCustomValidity(this.serverErrorValue)

    if (this.serverErrorValue) {
      this.errorSpan.textContent = this.serverErrorValue
      this.element.focus()
    } else {
      this.errorSpan.textContent = EMPTY_FIELD_MESSAGE
    }
  }

  validate(): void {
    this.element.checkValidity()
  }

  setEmailErrorMessage(): void {
    this.element.checkValidity()

    if (this.element.validity.typeMismatch) {
      this.errorSpan.textContent = EMAIL_INCORRECT_FORMAT
    } else if (this.element.validity.valueMissing) {
      this.errorSpan.textContent = EMPTY_FIELD_MESSAGE
    } else if (this.element.validity.customError) {
      this.errorSpan.textContent = this.element.validationMessage
    }
  }

  private get errorSpan(): HTMLSpanElement {
    return this.element.parentElement!.querySelector(ERROR_SELECTOR)!
  }
}
