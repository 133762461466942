import { Controller } from "@hotwired/stimulus"

import ModalController from "@/controllers/modal_controller"
import type { DeliveryOption } from "@/helpers/delivery_options"
import * as deliveryOptionsHelper from "@/helpers/delivery_options"
import { formatPrice } from "@/helpers/price_format"
import DiscountCodeController from "../discount_code_controller"
import type { DeliveryOptionEvent } from "./delivery_options_controller"
import type { QuantityEvent } from "./quantity_counter_controller"

export default class VoucherPaymentSummaryController extends Controller<HTMLInputElement> {
  static outlets = ["discount-code", "discount-modal"]
  declare readonly discountCodeOutlet: DiscountCodeController
  declare readonly discountModalOutlet: ModalController

  static targets = [
    "addDiscount",
    "appliedDiscountRow",
    "appliedPromoCodeInput",
    "deliveryOptionName",
    "deliveryOptionPrice",
    "formInputDeliveryName",
    "formInputDeliveryPrice",
    "formInputDeliverySupplierCode",
    "formInputOrderPrice",
    "formInputPostagePrice",
    "giftVoucherText",
    "giftVoucherTotal",
    "klarnaPrice",
    "loader",
    "postagePrice",
    "postageRow",
    "postageText",
    "totalPrice"
  ]

  declare readonly addDiscountTarget: HTMLButtonElement
  declare readonly appliedDiscountRowTarget: HTMLElement
  declare readonly appliedPromoCodeInputTarget: HTMLInputElement
  declare readonly hasAppliedPromoCodeInputTarget: boolean
  declare readonly deliveryOptionNameTarget: HTMLElement
  declare readonly deliveryOptionPriceTarget: HTMLElement
  declare readonly formInputDeliveryNameTarget: HTMLInputElement
  declare readonly formInputDeliveryPriceTarget: HTMLInputElement
  declare readonly formInputDeliverySupplierCodeTarget: HTMLInputElement
  declare readonly formInputOrderPriceTarget: HTMLInputElement
  declare readonly formInputPostagePriceTarget: HTMLInputElement
  declare readonly giftVoucherTextTarget: HTMLTableCellElement
  declare readonly giftVoucherTotalTarget: HTMLInputElement
  declare readonly klarnaPriceTarget: HTMLElement
  declare readonly loaderTarget: HTMLElement
  declare readonly postagePriceTarget: HTMLElement
  declare readonly postageRowTarget: HTMLElement
  declare readonly postageTextTarget: HTMLElement
  declare readonly totalPriceTarget: HTMLElement

  static values = {
    deliveryOption: Object,
    discountAmount: Number,
    quantity: Number,
    voucherPrice: Number
  }

  declare deliveryOptionValue: DeliveryOption
  declare discountAmountValue: number
  declare quantityValue: number
  declare voucherPriceValue: number

  appliedPromoCodeInputTargetConnected(element: HTMLElement): void {
    this.loaderTarget.hidden = true
    this.addDiscountTarget.hidden = true

    this.discountModalOutlet.close()

    if (element.dataset.amount) {
      this.discountAmountValue = parseFloat(element.dataset.amount)

      this.updateSummary()
    }
  }

  quantityValueChanged(_current: number, previous: number): void {
    // Don't update on initial load
    if (!previous) {
      return
    }

    this.discountCodeOutlet.updateApplicableVoucherPrice(this.giftVoucherTotal / 100)

    // If promo code is present, start the loader animation and re-apply the promo to reflect the
    // new quantity. That returns a Turbo stream, causing `appliedPromoCodeInputTargetConnected`
    // to update the summary. So we only update summary here if there's no promo.
    if (this.appliedPromoCode) {
      this.loaderTarget.hidden = false
      this.discountCodeOutlet.applyPromoToVoucher(this.appliedPromoCode)
    } else {
      this.updateSummary()
    }
  }

  deliveryOptionValueChanged(): void {
    this.updateSummary()
    this.togglePhysicalDeliveryInputs()
  }

  removeDiscount(): void {
    this.discountAmountValue = 0
    this.loaderTarget.hidden = true

    this.updateSummary()
    this.addDiscountTarget.hidden = false
    this.appliedDiscountRowTarget.replaceChildren()
  }

  setQuantity(event: QuantityEvent): void {
    this.quantityValue = event.detail.count
  }

  setDeliveryOption({ detail }: DeliveryOptionEvent): void {
    this.deliveryOptionValue = detail.option
  }

  private get appliedPromoCode(): string | null {
    // This target only present when returned by Turbo stream on successful promo code application.
    return this.hasAppliedPromoCodeInputTarget ? this.appliedPromoCodeInputTarget.value : null
  }

  private updateDeliveryOptionRow(): void {
    if (this.isEVoucher) {
      this.deliveryOptionNameTarget.textContent = this.deliveryOptionValue.name
      this.deliveryOptionPriceTarget.textContent = "FREE"
    } else {
      const totalDeliveryOptionPrice = this.deliveryPricePence * this.quantityValue
      this.deliveryOptionNameTarget.textContent = `${this.quantityPrefix}${this.deliveryOptionValue.name}`
      this.deliveryOptionPriceTarget.textContent = this.formattedPrice(totalDeliveryOptionPrice)

      // Physical delivery form inputs are disabled for evouchers, so their values don't matter
      this.formInputDeliveryNameTarget.value = this.deliveryOptionValue.name
      this.formInputDeliveryPriceTarget.value = totalDeliveryOptionPrice.toString()
      this.formInputDeliverySupplierCodeTarget.value = this.deliveryOptionValue.supplierCode
    }
  }

  private updatePostageRow(): void {
    this.postageRowTarget.hidden = !this.postagePrice

    if (this.postagePrice) {
      this.postageTextTarget.textContent = `
        ${!this.isGiftCard ? this.quantityPrefix : ""}Postage (First Class)
      `
      this.postagePriceTarget.textContent = this.formattedPrice(this.postagePrice)
      this.formInputPostagePriceTarget.setAttribute("value", this.postagePrice.toString())
    }
  }

  private updateGiftVoucherRow(): void {
    this.giftVoucherTextTarget.textContent = `${this.quantityPrefix}Gift Voucher`
    this.giftVoucherTotalTarget.innerText = this.formattedPrice(this.giftVoucherTotal)
  }

  private updateSummary(): void {
    this.updateGiftVoucherRow()
    this.updateDeliveryOptionRow()
    this.updatePostageRow()
    this.updateTotal()
  }

  private updateTotal(): void {
    const totalPrice = Math.max(this.totalPrice - this.discountAmountValue, 0)

    this.formInputOrderPriceTarget.value = this.orderPrice.toString()

    this.totalPriceTarget.innerText = this.formattedPrice(totalPrice)
    this.klarnaPriceTarget.innerText = this.formattedPrice(totalPrice / 3.0)
  }

  private get giftVoucherTotal(): number {
    return this.voucherPriceValue * this.quantityValue
  }

  private get orderPrice(): number {
    return deliveryOptionsHelper.orderPrice(
      this.voucherPriceValue,
      this.deliveryOptionValue,
      this.quantityValue
    )
  }

  private get totalPrice(): number {
    return deliveryOptionsHelper.totalPrice(
      this.voucherPriceValue,
      this.deliveryOptionValue,
      this.quantityValue
    )
  }

  private get postagePrice(): number | null {
    return deliveryOptionsHelper.postagePrice(this.deliveryOptionValue, this.quantityValue)
  }

  private get deliveryPricePence(): number {
    return deliveryOptionsHelper.pricePence(this.deliveryOptionValue)
  }

  private formattedPrice(pricePence: number): string {
    return formatPrice(pricePence / 100.0)
  }

  private get quantityPrefix(): string {
    return this.quantityValue > 1 ? `${this.quantityValue} x ` : ""
  }

  private get isEVoucher(): boolean {
    return deliveryOptionsHelper.isEVoucher(this.deliveryOptionValue)
  }

  private get isGiftCard(): boolean {
    return deliveryOptionsHelper.isGiftCard(this.deliveryOptionValue)
  }

  private togglePhysicalDeliveryInputs(): void {
    const isDisabled = this.isEVoucher

    this.formInputDeliveryNameTarget.disabled = isDisabled
    this.formInputDeliveryPriceTarget.disabled = isDisabled
    this.formInputPostagePriceTarget.disabled = isDisabled
    this.formInputDeliverySupplierCodeTarget.disabled = isDisabled
  }
}
