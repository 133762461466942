import { Controller } from "@hotwired/stimulus"

import { type DatepickerPorts } from "@/components/elm_datepicker"
import { Elm as ElmDatepicker } from "@/components/elm/Vouchers/EmailDispatchDatepicker.elm"
import { formatDMMM } from "@/helpers/date_format"

export default class EvoucherDeliveryController extends Controller<HTMLElement> {
  declare elmDatepicker: ElmApp<DatepickerPorts>

  static targets = [
    "datepickerInput",
    "deliveryScheduleOption",
    "email",
    "specificDateRadio",
    "specificDateLabel"
  ]

  declare readonly emailTarget: HTMLInputElement
  declare readonly datepickerInputTarget: HTMLInputElement
  declare readonly deliveryScheduleOptionTargets: HTMLInputElement[]
  declare readonly specificDateRadioTarget: HTMLInputElement
  declare readonly specificDateLabelTarget: HTMLSpanElement

  initialize(): void {
    this.elmDatepicker = ElmDatepicker.Vouchers.EmailDispatchDatepicker.init({
      node: document.getElementById("elm-email-dispatch-datepicker")
    })

    this.elmDatepicker.ports.dateChanged.subscribe((isoDate: string) =>
      this.setSpecificDate(isoDate)
    )

    this.elmDatepicker.ports.fetchRelativePosition.subscribe((className: string) => {
      window.requestAnimationFrame(() => {
        const picker = document.querySelector(`.${className}`)

        this.elmDatepicker.ports.setRelativePosition.send({
          boundingClientRect: picker?.getBoundingClientRect() || null,
          innerHeight: window.innerHeight,
          innerWidth: window.innerWidth
        })
      })
    })
  }

  toggleVisible(show: boolean): void {
    this.element.hidden = !show
    this.emailTarget.disabled = !show
    this.emailTarget.value = ""
    this.deliveryScheduleOptionTargets.forEach((target) => (target.disabled = !show))
  }

  handleSpecificDate(e: Event): void {
    // do not select the radio until a date has been chosen in the picker
    e.preventDefault()
    this.datepickerInputTarget.focus()
  }

  clearSpecificDate(): void {
    this.specificDateLabelTarget.innerHTML = "Specific date..."
    this.specificDateRadioTarget.value = ""
  }

  private setSpecificDate(isoDate: string): void {
    this.specificDateRadioTarget.checked = true
    this.specificDateRadioTarget.value = isoDate
    this.specificDateLabelTarget.innerHTML = `
      <strong>Custom:</strong> ${formatDMMM(new Date(isoDate))} (9am)
    `
  }
}
